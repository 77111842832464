import React, {useState, useEffect, useRef} from 'react'
import ImagenPeru from '../../assets/images/Login/banderaPeru.png'
import ImagenChile from '../../assets/images/Login/banderaChile.png'
import ImagenMexico from '../../assets/images/Login/banderaMexico.png'
import ImagenArgentina from '../../assets/images/Login/banderaArgentina.png'
import ImagenBolivia from '../../assets/images/Login/banderaBolivia.png'
import ImagenEeuu from '../../assets/images/Login/banderaEeuu.png'
import ImagenAndes from '../../assets/images/Login/banderaAndes.png'
import ImagenPeruMas from '../../assets/images/Login/banderaPeruMas.svg'
import VideoIniciarSesion from '../../assets/Videos/Login/videologin.mp4';
import {Form, Input, Select, Button, Row, Col, Modal } from "antd";
import {Link} from "react-router-dom";
import '../../styles/Login/FormularioLogin.css'
import {
    userSignIn,
    SeleccionarPaisReducer,
    CambiarFirstLoginReducer
} from '../../appRedux/actions/Auth';
import {useDispatch, useSelector} from "react-redux";
import { mostrarPaisesReducer } from "../../appRedux/actions/Auth";
import GrowLogoLogin from '../../assets/images/Login/growlogo.png'
import IconoWhastapp from '../../assets/images/Login/whastapplogo.png'
import GifCargandoChat from '../../assets/images/Login/cargandochat.gif'
// import Lottie from 'react-lottie-player'
import ChatCargando from './chatcargando.json'
import CronometroBloqueado from './CronometroBloqueado'
import IconoAzure from '../../assets/images/iconos/Login/azureicono.png'
import IconoGrowBlanco from '../../assets/images/iconos/Login/growiconoblanco.png'
import config from '../../config'

const FormularioLogin = (props) => {
    
    const [loginBloqueado, setLoginBloqueado] = useState(false);
    const [mostrarAzure, setMostrarAzure] = useState(false)


    const [txtPaisSeleccionado, setTxtPaisSeleccionado] = useState("0");
    const [txtPosicionPaisSeleccionado, setTxtPosicionPaisSeleccionado] = useState("");

    const [pasarMouseChat, setPasarMouseChat] = useState(false);

    const dispatch = useDispatch();

    const onFinish = async values =>  {

        values['pais'] = txtPaisSeleccionado
        values['posicionPais'] = txtPosicionPaisSeleccionado
        // console.log(values)
        // dispatch(showAuthLoader());
        
        dispatch(CambiarFirstLoginReducer())

        let rpta = await dispatch(userSignIn(values));
        // localStorage.removeItem('numeroIntentosLogin')
        if(rpta.logincorrecto == true){
            localStorage.removeItem('numeroIntentosLogin')
        }else{
            let numeroIntentosLogin
            if(localStorage.getItem('numeroIntentosLogin') == null){
                localStorage.setItem('numeroIntentosLogin', 1)
            }else{
                numeroIntentosLogin = parseInt(localStorage.getItem('numeroIntentosLogin')) +1
                localStorage.setItem('numeroIntentosLogin', numeroIntentosLogin)
            }

            if(numeroIntentosLogin >= 3){                
                setLoginBloqueado(true)
                localStorage.setItem('min-bloq-login', 5)
                localStorage.setItem('seg-bloq-login', 0)

                var fecha_actual = new Date();

                localStorage.setItem('hor-act-bloq-login', fecha_actual.getHours())
                localStorage.setItem('min-act-bloq-login', fecha_actual.getMinutes())
                localStorage.setItem('seg-act-bloq-login', fecha_actual.getSeconds())
            }
            
        }
    };

    const seleccionarPaisEspecifico = (valorPais) => {
        var pais = valorPais.split('-');
        setTxtPaisSeleccionado(pais[0])
        setTxtPosicionPaisSeleccionado(pais[1])
        dispatch(SeleccionarPaisReducer(pais[1]))
    }

    const {
        listaPaises,
        cargandoLogin
    } = useSelector(({auth}) => auth);

    useEffect(() => {
        dispatch(mostrarPaisesReducer())

        if(localStorage.getItem('min-bloq-login')){
            setLoginBloqueado(true)
        }

    }, []);

    const BtnLoginBloqueado = () => {

        var currentTime = new Date();

        // console.log(currentTime.getDate() + " date");

        // console.log(currentTime.getFullYear() + " year");
        // console.log(currentTime.getDay() + " dys");
        // console.log(currentTime.getDay() + " dys");
        
        

        // console.log(currentTime.getHours() + " hrs");
        // console.log(currentTime.getMinutes() + " min");
        // console.log(currentTime.getSeconds() + " sec");




    }


    const refWsp = useRef(null)

    return (
        <div id="Login-Contenedor-Formulario">
            <div style={{position:'absolute', width:'100%'}}>
                {
                    props.mostrarVideoLogin == true
                    ?<video autoPlay loop id="FullScreen-Video" >
                        <source src={VideoIniciarSesion}  type="video/mp4"/>
                        Your browser does not support the video tag.
                    </video>
                    :null
                }                
            </div>

            <div id="Login-Formulario-Capa" />
            {/* <img src={ImagenPortada} id="Login-Formulario-Fondo" /> */}
            <div id="Login-Formulario">
                {/* <h1
                    onClick={() => {
                        // window.location.reload(true);
                        console.log('click2')
                        
                        document.dispatchEvent(new KeyboardEvent('keyup', {
                            'key': 'F5'
                        }));

                    }}
                >Plataforma V4</h1> */}
                <Form
                    onFinish={onFinish}
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            // marginLeft:'-15px',
                            // width:'40vh'
                        }}
                    >
                        <img 
                            src={GrowLogoLogin}
                            id="Logo-Grow-Blanco-Negro-Login"
                        />
                    </div>
                    {/* <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            // background:'red',
                            marginLeft:'-15px',
                            width:'40vh'
                        }}
                    >
                        <p onClick={() => console.log(listaPaises)} id="Login-Formulario-Titulo" >Bienvenido al Futuro</p>
                    </div> */}
                    {/* <span id="Login-Formulario-Texto" style={{marginLeft: "-15px"}}>Usuario</span> */}

                    <Form.Item
                        name="pais"
                    >
                        <Select
                            // showSearch
                            placeholder="País"
                            style={{ 
                                // width: "299px",
                                // marginLeft: "-15px",
                                fontFamily: "Segoe UI",
                                fontStyle: "normal",
                                fontSize: "18px",
                                lineHeight: "21px",
                            }}
                            size={"large"}
                            onChange={(e) => seleccionarPaisEspecifico(e)}
                            // onChange={(e) => setTxtPaisSeleccionado(e)}
                        >
                            {
                                listaPaises.map((pais, posicion) => {
                                    return (
                                        <Select.Option 
                                            key={pais.paiid}
                                            // value={pais.paiid}>{pais.painombre}</Select.Option>
                                            value={pais.paiid+"-"+posicion}>{pais.painombre}</Select.Option>
                                    )
                                })
                            }
                            
                            {/* <Select.Option value="Chile">Chile</Select.Option>
                            <Select.Option value="Mexico">México</Select.Option>
                            <Select.Option value="Argentina">Argentina</Select.Option> */}
                        </Select>

                    </Form.Item>

                    <Form.Item
                        initialValue=""
                        name="usuario"
                        rules= {[{required: true, message:"Es necesario un usuario"}]}
                        style={{
                            // width: "300px"
                        }}
                    >
                        <Input 
                            // autoComplete={"off"}
                            id="email " 
                            className='Login-Formulario-Input'
                            // style={{marginLeft: "-15px"}}
                            placeholder="Correo" 
                        />

                    </Form.Item>
                    {/* <br/> */}
                    {/* <span id="Login-Formulario-Texto" style={{marginLeft: "-15px"}}>Contraseña</span> */}
                    <Form.Item
                        initialValue=""
                        rules= {[{required: true, message:"Es necesario una contraseña"}]}
                        name="contrasena"
                        style={{
                            // width: "300px"
                        }}
                    >
                        
                        <Input.Password 
                            placeholder="Contraseña"
                            type="password"
                            name="contrasena"
                            id="Login-Formulario-Input-Password"
                            // style={{marginLeft: "-15px"}}
                        />
                        
                    </Form.Item>
                    <br/>
                    <Link 
                        to="/recuperar" >
                            <span 
                                // style={{marginLeft: "-15px"}} 
                                id="Login-Formulario-Texto"
                            >¿Olvidaste tu contraseña?</span>
                    </Link>

                    <br/>

                    <div>
                        
                        <Button 
                            htmlType="submit"
                            disabled={
                                txtPaisSeleccionado == "0" || loginBloqueado == true
                                ?true
                                :false
                            }
                            style={{
                                // marginLeft: "-15px", 
                                borderRadius: "8px"
                            }}
                            id={
                                txtPaisSeleccionado == "0" || loginBloqueado == true
                                ?"Login-Formulario-Btn-Desactivado-Iniciar"
                                :"Login-Formulario-Btn-Iniciar"
                            }
                            loading={cargandoLogin}
                        >
                            {
                                loginBloqueado == true
                                ?<CronometroBloqueado
                                    setLoginBloqueado = {(e) => setLoginBloqueado(e)}
                                />
                                :null
                            }
                            <img src={IconoGrowBlanco} className="Icono-Azure-Login" />
                            <span style={{paddingLeft:'10px'}}>Iniciar con Grow</span>
                        </Button>

                        <Button 
                            style={{
                                // marginLeft: "-15px", 
                                borderRadius: "8px", 
                                background: '#1876F2'
                            }}
                            id={
                                "Login-Formulario-Btn-Iniciar"
                            }
                            onClick={() => {
                                // setMostrarAzure(!mostrarAzure)
                                window.location.href = config.apiazure+"api/log-in-azure";
                            }}
                        >
                            <img src={IconoAzure} className="Icono-Azure-Login" />
                            <span style={{paddingLeft:'10px'}}>Iniciar con Azure</span>
                        </Button>

                    </div>
                </Form>
                {/* <button
                    onClick={() => {
                        BtnLoginBloqueado()
                    }}
                >
                    click
                </button> */}
            </div>
            
            {
                mostrarAzure == true
                ?<Modal
                    centered
                    visible={mostrarAzure}
                    closeIcon={<div></div>}
                    footer={null}
                    width={"80%"}
                    className="Modal-Login-Azure"
                >

                    <iframe 
                        src={config.apiazure+"api/log-in-azure"}
                        frameborder="0"
                        width="100%"
                        height="90vh"
                    />

                </Modal>
                :null
            }

            <div
                id="Contenedor-Logos-Paises"
            >
                <Row>
                    <Col xl={24} xs={0} >
                        <img
                            id="Bandera-Pais-Login" 
                            src={ImagenPeru} />
                        <img
                            id="Bandera-Pais-Login" 
                            src={ImagenChile} />
                        <img
                            id="Bandera-Pais-Login" 
                            src={ImagenMexico} />
                        <img
                            id="Bandera-Pais-Login" 
                            src={ImagenArgentina} />
                        <img
                            id="Bandera-Pais-Login" 
                            src={ImagenBolivia} />
                        <img
                            id="Bandera-Pais-Login" 
                            src={ImagenEeuu} />
                    </Col>
                    <Col xl={0} xs={24}>
                        <img
                            id="Bandera-Pais-Mas-Login" 
                            src={ImagenPeruMas} />
                    </Col>

                </Row>
            </div>

            <div id="Contenedor-Logo-Grow-Login">
                <Row>
                    <Col xl={24} xs={0}>
                        <div id="Txt-Bienvenida-Login">
                            Bienvenido al<br/>Futuro del<br/>

                        </div>
                        <div className="Txt-Consumo-Masivo">
                            Consumo Masivo y<br/>Professional
                        </div>
                    </Col>
                    <Col xl={0} xs={24} >
                        <div id="Txt-Bienvenida-Mobil-Login">
                            Bienvenido al<br/>Futuro del<br/>

                        </div>
                        <div className="Txt-Consumo-Mobil-Masivo">
                            Consumo Masivo<br/>Professional
                        </div>
                    </Col>
                </Row>                
                {/* <img 
                    src={GrowLogoLogin}
                    id="Logo-Grow-Blanco-Negro-Login"
                /> */}
            </div>
            


            <div
                className='Disenio-Mensaje-Chat-Login'
                onClick={() => {
                    refWsp.current.click()
                }}
                onMouseEnter={() => {
                    setPasarMouseChat(true)
                }}
                onMouseLeave={() => {
                    setPasarMouseChat(false)
                }}
            >
                {/* <img src={GifCargandoChat} /> */}
                {/* <img src={"https://assets10.lottiefiles.com/private_files/lf30_HZOwZ6.json"} /> */}
                {
                    // pasarMouseChat == true
                    // ?<Lottie
                    //     loop
                    //     animationData={ChatCargando}
                    //     play
                    //     style={{ width: "160px", height: "160px" }}
                    // />
                    // :"Chat with us"
                }
                {
                    pasarMouseChat == true
                    ?<div className="Container-Point">
                        <span className="Point-Efecct-1"></span>
                        <span className="Point-Efecct-2"></span>
                        <span className="Point-Efecct-3"></span>
                    </div>
                    :"Chat with us"
                }
            </div>
            <div
                className='Btn-Wsp-Login'
                onClick={() => {
                    refWsp.current.click()
                }}
                onMouseEnter={() => {
                    setPasarMouseChat(true)
                }}
                onMouseLeave={() => {
                    setPasarMouseChat(false)
                }}
            >
                <img src={IconoWhastapp} className="Icono-Btn-Wsp-Login" />
                
            </div>

            <a 
                href="https://api.whatsapp.com/send?phone=51951331671"
                ref={refWsp}
                style={{display:'none'}}
                target="_blank"
            >
                
            </a>

        </div>
    )
}

export default FormularioLogin
