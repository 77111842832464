import {
    CAMBIAR_TITULO_AUDITORIA,
    DATA_FIL_VISITAS_USUARIO_AUDITORIA,

    CARGANDO_FIL_VISTAS_AUDITORIA,
    CARGANDO_FIL_TIEMPO_AUDITORIA,
    CARGANDO_FIL_USUGAN_AUDITORIA,
    CARGANDO_FIL_USUPER_AUDITORIA,

    OBTENER_FIL_VISTAS_AUDITORIA,
    OBTENER_FIL_TIEMPO_AUDITORIA,
    OBTENER_FIL_USUGAN_AUDITORIA,
    OBTENER_FIL_USUPER_AUDITORIA,

    DATA_FIL_VISTAS_AUDITORIA,
    DATA_FIL_TIEMPO_AUDITORIA,
    DATA_FIL_USUGAN_AUDITORIA,
    DATA_FIL_USUPER_AUDITORIA,

    DATA_TABLA_VISTAS_MODULOS_AUDITORIA,
    DATA_TABLA_VISTAS_SUBMODULOS_AUDITORIA,
    CARGANDO_TABLA_VISTAS_MODULOS_AUDITORIA,
    CARGANDO_TABLA_VISTAS_SUBMODULOS_AUDITORIA,

    CARGANDO_GRAFICO_VISTAS,
    CARGANDO_GRAFICO_TIEMPO,
    CARGANDO_GRAFICO_USUGAN,
    CARGANDO_GRAFICO_USUPER,

    OBTENER_DATA_EXCEL_DESCARGAR_AUDITORIA,
    CARGANDO_BTN_DESCARGAR_EXCEL_AUDITORIA
} from '../../../constants/Auditoria/Auditoria'

const INIT_STATE = {

    rex_titlo_auditoria : "",
    rex_dato_fil_visitas_usuario : {},

    rex_cargando_fil_vistas : true,
    rex_cargando_fil_tiempo : true,
    rex_cargando_fil_usugan : true,
    rex_cargando_fil_usuper : true,

    rex_dato_fil_vistas : {},
    rex_dato_fil_tiempo : {},
    rex_dato_fil_usugan : {},
    rex_dato_fil_usuper : {},

    rex_dato_tabla_vistas_modulos : [],
    rex_dato_tabla_vistas_submodulos : [],
    rex_cargando_tabla_vistas_modulos : true,
    rex_cargando_tabla_vistas_submodulos : true,

    rex_cargando_grafico_vistas : true,
    rex_cargando_grafico_tiempo : true,
    rex_cargando_grafico_usugan : true,
    rex_cargando_grafico_usuper : true,

    rex_data_excel_descargar_auditoria : [],

    rex_cargando_btn_descargar_excel_auditoria : false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case CAMBIAR_TITULO_AUDITORIA: {
            return {
                ...state,
                rex_titlo_auditoria : action.payload
            }
        }
        case DATA_FIL_VISITAS_USUARIO_AUDITORIA: {
            return {
                ...state,
                rex_dato_fil_visitas_usuario : action.payload
            }
        }
        case CARGANDO_FIL_VISTAS_AUDITORIA: {
            return {
                ...state,
                rex_cargando_fil_vistas: action.payload
            }
        }
        case CARGANDO_FIL_TIEMPO_AUDITORIA: {
            return {
                ...state,
                rex_cargando_fil_tiempo: action.payload
            }
        }
        case CARGANDO_FIL_USUGAN_AUDITORIA: {
            return {
                ...state,
                rex_cargando_fil_usugan: action.payload
            }
        }
        case CARGANDO_FIL_USUPER_AUDITORIA: {
            return {
                ...state,
                rex_cargando_fil_usuper: action.payload
            }
        }

        case DATA_FIL_VISTAS_AUDITORIA: {
            return {
                ...state,
                rex_dato_fil_vistas: action.payload
            }
        }
        case DATA_FIL_TIEMPO_AUDITORIA: {
            return {
                ...state,
                rex_dato_fil_tiempo: action.payload
            }
        }
        case DATA_FIL_USUGAN_AUDITORIA: {
            return {
                ...state,
                rex_dato_fil_usugan: action.payload
            }
        }
        case DATA_FIL_USUPER_AUDITORIA: {
            return {
                ...state,
                rex_dato_fil_usuper: action.payload
            }
        }

        case DATA_TABLA_VISTAS_MODULOS_AUDITORIA: {
            return {
                ...state,
                rex_dato_tabla_vistas_modulos : action.payload
            }
        }
        case DATA_TABLA_VISTAS_SUBMODULOS_AUDITORIA: {
            return {
                ...state,
                rex_dato_tabla_vistas_submodulos : action.payload
            }
        }
        case CARGANDO_TABLA_VISTAS_MODULOS_AUDITORIA: {
            return {
                ...state,
                rex_cargando_tabla_vistas_modulos : action.payload
            }
        }
        case CARGANDO_TABLA_VISTAS_SUBMODULOS_AUDITORIA: {
            return {
                ...state,
                rex_cargando_tabla_vistas_submodulos : action.payload
            }
        }


        case CARGANDO_GRAFICO_VISTAS: {
            return {
                ...state,
                rex_cargando_grafico_vistas : action.payload
            }
        }
        case CARGANDO_GRAFICO_TIEMPO: {
            return {
                ...state,
                rex_cargando_grafico_tiempo : action.payload
            }
        }
        case CARGANDO_GRAFICO_USUGAN: {
            return {
                ...state,
                rex_cargando_grafico_usugan : action.payload
            }
        }
        case CARGANDO_GRAFICO_USUPER: {
            return {
                ...state,
                rex_cargando_grafico_usuper : action.payload
            }
        }
        case OBTENER_DATA_EXCEL_DESCARGAR_AUDITORIA: {
            return {
                ...state,
                rex_data_excel_descargar_auditoria : action.payload
            }
        }
        case CARGANDO_BTN_DESCARGAR_EXCEL_AUDITORIA: {
            return {
                ...state,
                rex_cargando_btn_descargar_excel_auditoria : action.payload
            }
        }

        default:
            return state;
    }
}