import React, {useState, useEffect} from 'react'

const CronometroBloqueado = (props) => {

    const [mins, setMinutes] = useState( localStorage.getItem('min-bloq-login')?localStorage.getItem('min-bloq-login') :5);
    const [secs, setSeconds] = useState( localStorage.getItem('seg-bloq-login')?localStorage.getItem('seg-bloq-login') :0);

    // const [mins, setMinutes] = useState( 0 );
    // const [secs, setSeconds] = useState( 10 );

    const setLoginBloqueado = props.setLoginBloqueado

    useEffect(() => {
        
        let sampleInterval = setInterval(() => {
            if (secs > 0) {
                setSeconds(secs - 1);
                localStorage.setItem('seg-bloq-login', secs - 1)
            }
            if (secs === 0) {
                if (mins <= 0) {
                    clearInterval(sampleInterval);
                    localStorage.removeItem('numeroIntentosLogin')
                    localStorage.removeItem('min-bloq-login')
                    localStorage.removeItem('seg-bloq-login')
                    setLoginBloqueado(false)
                } else {
                    setMinutes(mins - 1);
                    setSeconds(59);
                    localStorage.setItem('min-bloq-login', mins - 1)
                    localStorage.setItem('seg-bloq-login', 59)
                }
            }
        }, 1000);

        return () => {
            clearInterval(sampleInterval);
        };

    });

    return (
        <>
            {!(mins || secs) ? "" : (
                <>
                    {" "}
                    {mins}:{secs < 10 ? `0${secs} ` : secs}
                </>
            )}
        </>
    )
}

export default CronometroBloqueado