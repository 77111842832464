



// CONTROLES
    // ACCESOS
        // PERMISOS
        export const CONTROLES_ACCESOS_PERMISOS_CARGANDO_TABLA                  = 'CONTROLES_ACCESOS_PERMISOS_CARGANDO_TABLA'
        export const CONTROLES_ACCESOS_PERMISOS_CARGANDO_NUEVO_PERMISO          = 'CONTROLES_ACCESOS_PERMISOS_CARGANDO_NUEVO_PERMISO'
        export const CONTROLES_ACCESOS_PERMISOS_OBTENER_PERMISOS                = 'CONTROLES_ACCESOS_PERMISOS_OBTENER_PERMISOS'
        export const CONTROLES_ACCESOS_PERMISOS_OBTENER_COLUMNAS_TABLA_PERMISOS = 'CONTROLES_ACCESOS_PERMISOS_OBTENER_COLUMNAS_TABLA_PERMISOS'
        export const CONTROLES_ACCESOS_PERMISOS_VISIBILIDAD_MODAL_NUEVO_PERMISO = 'CONTROLES_ACCESOS_PERMISOS_VISIBILIDAD_MODAL_NUEVO_PERMISO'
        
        // TIPOS USUARIOS
        export const CONTROLES_ACCESOS_TIPOS_USUARIOS_CARGANDO_TABLA                        = 'CONTROLES_ACCESOS_TIPOS_USUARIOS_CARGANDO_TABLA'
        export const CONTROLES_ACCESOS_TIPOS_USUARIOS_OBTENER_TIPOS_USUARIOS                = 'CONTROLES_ACCESOS_TIPOS_USUARIOS_OBTENER_TIPOS_USUARIOS'
        export const CONTROLES_ACCESOS_TIPOS_USUARIOS_OBTENER_COLUMNAS_TABLA_TIPOS_USUARIOS = 'CONTROLES_ACCESOS_TIPOS_USUARIOS_OBTENER_COLUMNAS_TABLA_TIPOS_USUARIOS'
        export const CONTROLES_ACCESOS_TIPOS_USUARIOS_VISIBILIDAD_MODAL_NUEVO_TIPO_USUARIO  = 'CONTROLES_ACCESOS_TIPOS_USUARIOS_VISIBILIDAD_MODAL_NUEVO_TIPO_USUARIO'
        export const CONTROLES_ACCESOS_TIPOS_USUARIOS_CARGANDO_NUEVO_TIPO_USUARIO           = 'CONTROLES_ACCESOS_TIPOS_USUARIOS_CARGANDO_NUEVO_TIPO_USUARIO'
        export const ACTUALIZAR_DATA_PERMISOS_TIPO_USUARIO                                  = 'ACTUALIZAR_DATA_PERMISOS_TIPO_USUARIO'
        export const ACTUALIZAR_CARGANDO_PERMISOS_TIPO_USUARIO                              = 'ACTUALIZAR_CARGANDO_PERMISOS_TIPO_USUARIO'

            // PERMISOS
            export const OBTENER_PERMISOS_TIPO_USUARIO_CONTROL_ACCESO = 'OBTENER_PERMISOS_TIPO_USUARIO_CONTROL_ACCESO'
            export const CARGANDO_PERMISOS_TIPO_USUARIO_CONTROL_ACCESO = 'CARGANDO_PERMISOS_TIPO_USUARIO_CONTROL_ACCESO'
            export const PODER_GUARDAR_PERMISOS_TIPO_USUARIO_CONTROL_ACCESO = 'PODER_GUARDAR_PERMISOS_TIPO_USUARIO_CONTROL_ACCESO'
            

        // USUARIOS
        export const CONTROLES_ACCESOS_USUARIOS_CARGANDO_TABLA                  = 'CONTROLES_ACCESOS_USUARIOS_CARGANDO_TABLA'
        export const CONTROLES_ACCESOS_USUARIOS_OBTENER_USUARIOS                = 'CONTROLES_ACCESOS_USUARIOS_OBTENER_USUARIOS'
        export const CONTROLES_ACCESOS_USUARIOS_OBTENER_COLUMNAS_TABLA_USUARIOS = 'CONTROLES_ACCESOS_USUARIOS_OBTENER_COLUMNAS_TABLA_USUARIOS'
        export const CONTROLES_ACCESOS_USUARIOS_VISIBILIDAD_MODAL_NUEVO_USUARIO = 'CONTROLES_ACCESOS_USUARIOS_VISIBILIDAD_MODAL_NUEVO_USUARIO'
        export const CONTROLES_ACCESOS_USUARIOS_CARGANDO_NUEVO_USUARIO          = 'CONTROLES_ACCESOS_USUARIOS_CARGANDO_NUEVO_USUARIO'

// ADMINISTRADOR

    // CONTROL DE DATA
    export const CARGANDO_CREAR_ARCHIVO_DATA_CONTROL_DATA = 'CARGANDO_CREAR_ARCHIVO_DATA_CONTROL_DATA'
    export const CARGANDO_EDITAR_ARCHIVO_DATA_CONTROL_DATA = 'CARGANDO_EDITAR_ARCHIVO_DATA_CONTROL_DATA'
    export const CARGANDO_DATA_ARCHIVO_DATA_CONTROL_DATA  = 'CARGANDO_DATA_ARCHIVO_DATA_CONTROL_DATA'
    export const OBTENER_DATA_ARCHIVO_DATA_CONTROL_DATA   = 'OBTENER_DATA_ARCHIVO_DATA_CONTROL_DATA'
    export const SELECCIONAR_DATA_ARCHIVO_DATA_CONTROL_DATA = 'SELECCIONAR_DATA_ARCHIVO_DATA_CONTROL_DATA'

    // MODULOS
    export const OBTENER_MODULOS_ADMINISTRACION = 'OBTENER_MODULOS_ADMINISTRACION'
    export const CARGANDO_DATA_MODULOS_ADMINISTRACION = 'CARGANDO_DATA_MODULOS_ADMINISTRACION'
    export const CARGANDO_NUEVO_MODULO_ADMINISTRACION = 'CARGANDO_NUEVO_MODULO_ADMINISTRACION'
    export const CARGANDO_NUEVO_SUBMODULO_ADMINITRACION = 'CARGANDO_NUEVO_SUBMODULO_ADMINITRACION'
    
    