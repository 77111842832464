
const INIT_STATE = {
    
};


export default (state = INIT_STATE, action) => {
  switch (action.type) {
    default:
      return state;
  }
}
