export const CAMBIAR_TITULO_AUDITORIA = "CAMBIAR_TITULO_AUDITORIA"
export const DATA_FIL_VISITAS_USUARIO_AUDITORIA = "DATA_FIL_VISITAS_USUARIO_AUDITORIA"
export const CARGANDO_FIL_VISTAS_AUDITORIA = "CARGANDO_FIL_VISTAS_AUDITORIA"
export const CARGANDO_FIL_TIEMPO_AUDITORIA = "CARGANDO_FIL_TIEMPO_AUDITORIA"
export const CARGANDO_FIL_USUGAN_AUDITORIA = "CARGANDO_FIL_USUGAN_AUDITORIA"
export const CARGANDO_FIL_USUPER_AUDITORIA = "CARGANDO_FIL_USUPER_AUDITORIA"


export const OBTENER_FIL_VISTAS_AUDITORIA = "OBTENER_FIL_VISTAS_AUDITORIA"
export const OBTENER_FIL_TIEMPO_AUDITORIA = "OBTENER_FIL_TIEMPO_AUDITORIA"
export const OBTENER_FIL_USUGAN_AUDITORIA = "OBTENER_FIL_USUGAN_AUDITORIA"
export const OBTENER_FIL_USUPER_AUDITORIA = "OBTENER_FIL_USUPER_AUDITORIA"


export const DATA_FIL_VISTAS_AUDITORIA = "DATA_FIL_VISTAS_AUDITORIA"
export const DATA_FIL_TIEMPO_AUDITORIA = "DATA_FIL_TIEMPO_AUDITORIA"
export const DATA_FIL_USUGAN_AUDITORIA = "DATA_FIL_USUGAN_AUDITORIA"
export const DATA_FIL_USUPER_AUDITORIA = "DATA_FIL_USUPER_AUDITORIA"

export const DATA_TABLA_VISTAS_MODULOS_AUDITORIA = 'DATA_TABLA_VISTAS_MODULOS_AUDITORIA'
export const DATA_TABLA_VISTAS_SUBMODULOS_AUDITORIA = 'DATA_TABLA_VISTAS_SUBMODULOS_AUDITORIA'
export const CARGANDO_TABLA_VISTAS_MODULOS_AUDITORIA = 'CARGANDO_TABLA_VISTAS_MODULOS_AUDITORIA'
export const CARGANDO_TABLA_VISTAS_SUBMODULOS_AUDITORIA = 'CARGANDO_TABLA_VISTAS_SUBMODULOS_AUDITORIA'

export const CARGANDO_GRAFICO_VISTAS = 'CARGANDO_GRAFICO_VISTAS'
export const CARGANDO_GRAFICO_TIEMPO = 'CARGANDO_GRAFICO_TIEMPO'
export const CARGANDO_GRAFICO_USUGAN = 'CARGANDO_GRAFICO_USUGAN'
export const CARGANDO_GRAFICO_USUPER = 'CARGANDO_GRAFICO_USUPER'

export const OBTENER_DATA_EXCEL_DESCARGAR_AUDITORIA = 'OBTENER_DATA_EXCEL_DESCARGAR_AUDITORIA'
export const CARGANDO_BTN_DESCARGAR_EXCEL_AUDITORIA = 'CARGANDO_BTN_DESCARGAR_EXCEL_AUDITORIA'